<template>
	<div 
		v-if="userEdit"
		class="flex flex-col content-start h-full">
		
		<div class="flex-none bg-beige flex flex-col h-32">
			<div class="flex-grow flex px-4 pt-4">
				<div 
					class="flex-none w-12 h-12 flex items-center justify-center"
					@click="$emit('close')">
					
					<i class="fas fa-times text-black text-xl" />
				</div>
				<div class="flex-grow flex items-center justify-center">
					<h1>{{userEdit.email}}</h1>
				</div>
				<div class="flex-none w-12 h-12 flex items-center justify-center">
					
				</div>
			</div>
			
			<Tabs 
				class="flex-none justify-center"
				:tabs="[
					{
						name: 'Detaljer',
						alias: 'details',
					},
					{
						name: 'Passord',
						alias: 'password',
					},
					{
						name: 'Transaksjoner',
						alias: 'transactions',
					},
				]" 
				:active="activeSection" 
				@click="activeSection = $event.alias"
			/>
		</div>
		
		<section 
			v-if="activeSection == 'details'"
			class="flex-grow overflow-hidden h-full flex flex-col">
			
			<div class="flex-grow overflow-auto px-8 py-2">
				<div 
					v-if="userEdit.status == 'FORCE_CHANGE_PASSWORD'"
					class="bg-orange-400 text-white p-4 rounded mt-4">
					
					<p>
						OBS: Denne brukeren har blitt bedt om å sette passord
					</p>
				</div>
				
				
				<!-- <div class="bg-beige p-12">
					
					userEdit:
					<pre>{{ userEdit }}</pre>
					
					<textarea 
						v-model="smsContent" 
						class="w-full h-100 mb-4"
					/>
					
					<a 
						class="button submit" 
						@click.prevent="onSendSMS()" >
						
						Send SMS
					</a>
					
				</div> -->
				
				
				
				
				
				
				
				<form @submit.prevent="onUpdateUser()">
					<label for="">E-post</label>
					<div class="text-black">
						{{userEdit.email}}
					</div>
					
					<InputSingleline 
						v-model="userEdit.given_name"
						type="text"
						label="Fornavn"
						:required="true"
					/>
					
					<InputSingleline 
						v-model="userEdit.family_name"
						type="text"
						label="Etternavn"
						:required="true"
					/>
					
					<!-- <div>
						<label for="">Språkinnstilling</label>
						<select 
							v-model="userEdit.locale"
							class="">
							
							<option value="nb">Norsk</option>
							<option value="en">English</option>
						</select>
					</div> -->
					
					<InputSingleline 
						v-model="userEdit.phone_number"
						type="text"
						label="Telefonnummer"
						placeholder="+47xxxxxxxx"
						:required="false"
					/>
					
					<div v-if="isSuperAdmin">
						<InputSingleline 
							v-model="userEdit.comment"
							type="text"
							label="Kommentar (kun synlig for SuperAdmins)"
						/>
					</div>
					
					<div>
						<label for="">Brukerrettigheter</label>
						<select 
							v-model="userEdit.role"
							class="">
							
							<option value="admin">Administrator</option>
							<option value="regular">Normal</option>
						</select>
					</div>
					
					<div v-if="userEdit.role != 'admin'">
						<label for="">Delta i avdeling(er)</label>
						<div v-if="departments && departments.length">
							<div 
								v-for="department in departments"
								:key="'userEditDepartment_'+department.departmentId"
								@click="onToggleDepartment(department.departmentId)"
								class="cursor-default flex my-4 items-start">
								
								<input 
									type="checkbox" 
									:checked="Array.isArray(userEdit.departments) ? userEdit.departments.find( uD => uD == department.departmentId) : false"
								/>
								{{department.name}}
							</div>
						</div>
						<em v-else>
							Ingen avdelinger funnet ennå.
						</em>
					</div>
					
					
					<label for="picture">Profilbilde</label>
					<div 
						v-if="userEdit.picture"
						class="w-full p-8 object-contain bg-beige relative">
						
						<img 
							:src="fileKeyToUrl( userEdit.picture )"
							alt="Logo"
							class="object-contain h-32 mx-auto"
							style="max-width: 20rem;"
						/>
						
						<div class="absolute top-0 right-0 m-2">
							<i 
								class="bg-white p-2 fas fa-trash ml-2"
								@click="userEdit.picture = null"
							/>
						</div>
					</div>
					<InputUpload
						v-else
						label="Last opp profilbilde"
						name="image"
						:multiple="false"
						@input="onImageAdded($event)"
					/>
				</form>
			</div>
			
			<div class="flex-none p-6 flex">
				<div class="flex-grow">
					<a 
						class="button warning" 
						@click.prevent="onDeleteUser()" >
						
						Slett
					</a>
				</div>
				<div class="flex-none space-x-4 text-right">
					<a 
						class="button" 
						@click="$emit('close')">
						
						Avbryt
					</a>
					
					<button 
						type="submit"
						@click.prevent="onUpdateUser()" 
						:disabled="loading == 'updating'"
						class="button submit"
						:class="{'loading' : loading == 'updating'}">
						
						Lagre
					</button>
				</div>
			</div>
			
		</section>
		
		<section 
			v-else-if="activeSection == 'password'"
			class="flex-grow overflow-auto h-full p-8">
			
			<p>
				Du kan som administrator sette et nytt passord for denne brukeren. Det kan du kun gjøre om denne brukeren har kun denne Upsell-kontoen tilknyttet sin brukerprofil. 
			</p>
			
			<button 
				@click.prevent="onResetPassword()" 
				:disabled="loading == 'resetting'"
				class="button dark"
				:class="{'loading' : loading == 'resetting'}">
				
				Sett nytt passord
			</button>
		</section>
		<section 
			v-else-if="activeSection == 'transactions'"
			class="flex-grow overflow-auto h-full">
			
			<TransactionsBrowse 
				:userPassed="userPassed" 
			/>
		</section>
	</div>
</template>



<script>
export default {
	components: {
		'TransactionsBrowse': () => import('@/components/transactions/TransactionsBrowse.vue'),
	},
	
	props: {
		userPassed: {
			type: Object,
			required: true,
		}
	},
	
	computed:{
		departments(){
			return this.$store.getters.getDepartments;
		},
	},
	
	data() {
		return {
			loading: null,
			userEdit: null,
			activeSection: 'details',
			smsContent: '',
		}
	},
	
	methods: {
		phonenumberIsValid(phonenumber){
			return phonenumber.startsWith('+');
		},
		
		onImageAdded( file ){
			this.userEdit.picture = this.accountId+'/' + file.fileId +'.'+ file.extension;
		},
		
		async onResetPassword(){
			try {
				this.loading = 'resetting';
				let password = prompt('Nytt passord', '');
				
				if (password.length < 8) {
					throw Error( 'Passord må inneholder mer enn 8 tegn' );
				}
				
				const deleteUserResponse = await this.$store.dispatch('resetPassword', {
					...this.userEdit,
					password,
				});
				
				await this.$store.dispatch('listUsers');
				this.$notify({ type: 'success', text: 'Brukeren fikk satt nytt passord. Husk å informere om passord på en sikker måte.' });
				// this.$emit('close');
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke sett passord på brukeren'
				});
				
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		async onDeleteUser(){
			try {
				if (! confirm('Er du sikker på at du vil slette brukeren?')) return; 
				this.loading = 'deleting';
				const deleteUserResponse = await this.$store.dispatch('deleteUser', this.userEdit.username);
				await this.$store.dispatch('listUsers');
				this.$notify({ type: 'success', text: 'Brukeren ble slettet' });
				this.$emit('close');
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke slette brukeren'
				});
				
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		async onUpdateUser(){
			try {
				this.loading = 'updating';
				
				if (
					!this.userEdit.family_name || 
					this.userEdit.family_name == '' || 
					!this.userEdit.given_name || 
					this.userEdit.given_name == '' ) {
					
					throw Error( 'En bruker må ha fornavn og etternavn' );
				}
				
				if (
					this.userEdit.phone_number && 
					this.userEdit.phone_number != '' ) {
					
					if ( !this.phonenumberIsValid( this.userEdit.phone_number ) ){
						throw Error( 'Husk å fylle inn telefonnummber med prefix (f.eks. +47)' );
					}
					
					this.userEdit.phone_number = this.userEdit.phone_number.replace(/\s/g, "");
				}
				
				const updateUserResponse = await this.$store.dispatch('updateUser', this.userEdit);
				await this.$store.dispatch('listUsers');
				this.$notify({ type: 'success', text: 'Endringen ble lagret' });
				// this.$emit('close');
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke lagre endringen'
				});
				
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		onToggleDepartment( departmentId ){
			if ( !Array.isArray(this.userEdit.departments) ) {
				this.userEdit.departments = [];
			}
			
			if ( this.userEdit.departments.find( uD => uD == departmentId) ) {
				this.userEdit.departments = this.userEdit.departments.filter( uD => uD != departmentId );
			}
			else {
				this.userEdit.departments.push( departmentId );
			}
		},
		
		createCopy(){
			// console.log('this.userPassed', this.userPassed);
			this.userEdit = JSON.parse(
				JSON.stringify({
					role: this.userPassed.departments == 'admin' ? 'admin' : 'regular',
					departments: [],
					picture: null,
					...this.userPassed,
				})
			);
		},
	},
	
	async mounted(){
		// await this.$store.dispatch('getUser', this.signedInUserPassed.username);
		this.createCopy();
	},
}
</script>

<style lang="scss">
</style>